@import "reset.scss";
@import "common.scss";

body {
  font-family: $defontStyle;
  background-color: $bodyColor;
}
//common//
.container {
  @include containerWidth;
}
.layout_container {
  padding-top: 80px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  background: $headerColor;
  z-index: 1;
  .header_inner {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    @include centeringMr;
  }
  .header_logo {
    @include centeringMc;
  }
  .header_logo_link {
    width: 100%;
  }
  .logo_svg {
    display: block;
    width: 100%;
    margin: 0 auto 0 10px;
    @include mediaQuery("sp") {
      width: 80%;
    }
  }
  .gnav-list {
    width: 50%;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    @include mediaQuery("sp") {
      display: none;
    }
    .gnav-item {
      margin: 30px;
      .gnav-link {
        text-decoration: none;
        color: $defontColor;
      }
    }
  }

  .sp_nav {
    display: none;
    width: 50%;
    height: 50px;
    margin-left: auto;
    color: white;
    @include mediaQuery("sp") {
      @include centeringMr;
    }
    svg {
      font-size: 2.2rem;
      color: rgb(87, 118, 255);
      margin: 0 20px;
      opacity: 1;
      @include mediaQuery("sp") {
        margin: 0 15px;
      }
    }
  }
}

.footer_container {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 100%;
}
.footer {
  bottom: 0;
  width: 100%;
  height: 250px;
  align-items: center;
  background: $hooterColor;
  padding: 0 3% 0 3%;
  z-index: 1;
  display: -webkit-flex;
  align-items: center;
  .footer_inner {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    @include mediaQuery("sp~") {
      @include centeringMl;
      flex-direction: row-reverse;
    }
  }
  /*   @include mediaQuery("sp~") {
    @include centeringMl;
    flex-direction: row-reverse;
  } */
  .footer_l {
    /* width: 60%; */
    @include mediaQuery("sp") {
      width: 100%;
    }
    .footer_l_sp {
      display: none;

      @include mediaQuery("sp") {
        display: block;
      }
      .gnav-list {
        @include centeringMc;
      }
      .gnav-item {
        margin: 30px;
        .gnav-link {
          text-decoration: none;
          color: $defontColor;
        }
      }
    }
    .footer-list {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      @include mediaQuery("sp") {
        @include centeringMc;
        flex-wrap: wrap;
      }
    }
    .footer-item {
      margin: 20px;
      a {
        color: $defontColor;
      }
      @include mediaQuery("sp") {
        margin: 10px;
      }
      .footer-link {
        text-decoration: none;
        color: $defontColor;
      }
    }
  }
  .footer_r {
    width: 100%;
    @include mediaQuery("sp") {
      @include centeringMc;
      width: 100%;
      padding: 20px 0;
    }
  }
}
.page_scroll {
  @include centeringMc;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  height: 80px;
  .top_b {
    text-align: center;
    width: 100%;
    font-size: 1.8rem;
    opacity: 0.9;
    @include mediaQuery("sp") {
      display: none;
    }
    :hover {
      transition: 0.2s;
      filter: brightness(90%);
      transform: translateY(-3px);
    }
    .system_b {
      background: linear-gradient(to bottom, #ffffff, #2d7baf, #ffffff);
    }
    .improv_b {
      background: linear-gradient(to bottom, #ffffff, #59b18c, #ffffff);
    }
    .web_b {
      background: linear-gradient(to bottom, #ffffff, #999999, #ffffff);
    }
    button {
      margin: 20px;
      width: 20%;
      height: 50px;
      cursor: pointer;
      box-shadow: $defShadow;
      font-family: $stitlefontStyle;
      font-weight: bold;
      color: $defontColor;
      border: 0;
    }
  }
  svg {
    font-size: $fontSize-h1;
    color: rgb(146, 145, 145);
    margin: auto 30px auto auto;
    opacity: 0.8;
    cursor: pointer;
    :hover {
      transition: 0.2s;
      opacity: 0.6;
      transform: translateY(-50px);
    }
  }
}
//popup//
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  display: none;
}

.popup_inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  padding: 30px;
  background-color: $bodyColor;
  z-index: 2;
  border-radius: 5px;
}
.popup_btn_area {
  @include centeringMc;
}
.close_btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.close-btn i {
  font-size: 20px;
  color: #333;
}
.popup_background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  cursor: pointer;
}
//top//
.top-slider {
  width: 100%;
  padding-bottom: 50px;
  @include mediaQuery("sp") {
    display: none;
  }
}
.top-slider img {
  opacity: 0.5;
  width: 100%;
}
[class*="top_message-"] {
  font-family: $stitlefontStyle;
  font-size: 2.2rem;
  position: absolute;
  margin: 2%;
  @include mediaQuery("tab") {
    font-size: 2rem;
  }
  @include mediaQuery("sp") {
    font-size: 1.6rem;
  }
}
.top_message-1 {
  top: 0;
}
.top_message-2 {
  top: 10%;
  left: 10%;
}
.top_message-3 {
  top: 45%;
  left: 30%;
}
.top_message-4 {
  top: 30%;
  left: 10%;
}
.top_message-5 {
  top: 45%;
  left: 10%;
}
.news {
  width: 100%;
  height: auto;
  background: $newswebtitleBgcolor;
  box-shadow: $defShadow;
  margin-bottom: 20px;
  padding-bottom: 1%;
  text-align: center;
  h2 {
    padding-top: 10px;
  }
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.info {
  margin: 1em auto;
  padding: 0 1.5em;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #444;
  overflow: auto;
  width: 95%;
  height: 10em;
  background-color: #fcfcfc;
}
.info dl {
  padding: 0.5em 0;
  border-bottom: 1px solid #ccc;
}
.info dt {
  padding-bottom: 0;
  width: 6em;
}
@media screen and (min-width: 480px) {
  .info dt {
    clear: left;
    float: left;
  }
  .info dd {
    margin-left: 0.5em;
  }
}
.service_link {
  img {
    width: 100%;
    height: 100%;
    opacity: 0.9;
  }
}

[class*="service-0"] {
  position: relative;
  display: -webkit-flex;
  width: 100%;
  height: auto;
  box-shadow: $defShadow;
  margin-top: 20px;
  @include mediaQuery("sp") {
    height: 300px;
  }
}
.service_title {
  position: absolute;
  @include centeringMl;
  width: 50%;
  height: 30%;
  top: 15%;
  left: 1%;
  opacity: 0.9;
  background: $sevtitleBgcolor;
  color: $sevtfontColor;
  border: $defShadow;
  border-radius: 10px;
  font-size: 2.2rem;
  @include mediaQuery("sp") {
    width: 90%;
    height: 20%;
  }
  .service_icon {
    width: 13%;
    height: 70%;
    background-color: rgb(45, 123, 175);
    margin: 0 3% 0 3%;
    @include mediaQuery("sp") {
      width: 40px;
      height: 40px;
    }
  }
}
.service_title_r {
  position: absolute;
  @include centeringMl;
  width: 50%;
  height: 30%;
  top: 15%;
  left: 49%;
  opacity: 0.9;
  background: $sevtitleBgcolor;
  color: $sevtfontColor;
  border: $defShadow;
  border-radius: 10px;
  font-size: 2.2rem;
  @include mediaQuery("sp") {
    top: 15%;
    left: 1%;
    width: 90%;
    height: 20%;
  }
  .service_icon {
    width: 13%;
    height: 70%;
    background-color: rgb(89, 177, 140);
    margin: 0 3% 0 3%;
    @include mediaQuery("sp") {
      width: 40px;
      height: 40px;
    }
  }
}
.service_title_s {
  position: absolute;
  @include centeringMl;
  width: 50%;
  height: 30%;
  top: 15%;
  left: 1%;
  opacity: 0.9;
  background: $sevtitleBgcolor;
  color: $sevtfontColor;
  border: $defShadow;
  border-radius: 10px;
  font-size: 2.2rem;
  @include mediaQuery("sp") {
    width: 90%;
    height: 20%;
  }
  .service_icon {
    width: 13%;
    height: 70%;
    background-color: rgb(0, 0, 0);
    margin: 0 3% 0 3%;
    @include mediaQuery("sp") {
      width: 40px;
      height: 40px;
    }
  }
}
.service_content {
  position: absolute;
  @include centeringMl;
  width: 0;
  height: 25%;
  top: 65%;
  left: 5%;
  opacity: 0;
  background-color: rgb(196, 231, 255);
  color: $defontColor;
  border: $defShadow;
  border-radius: 10px;
  font-weight: bold;

  @include mediaQuery("sp") {
    top: 40%;
    height: 50%;
  }
  .service_content_dis {
    font-size: 1.8rem;
  }
  .content_icon {
    width: 5%;
    height: 60%;
    background-color: rgb(126, 53, 209);
    border-radius: 10px;
    opacity: 0.7;
    margin: 0 2% 0 2%;
    @include mediaQuery("sp") {
      display: none;
    }
  }
}
.service_content_r {
  position: absolute;
  @include centeringMl;
  width: 0;
  height: 25%;
  top: 65%;
  left: 5%;
  opacity: 0;
  background-color: rgb(196, 231, 255);
  color: $defontColor;
  border: $defShadow;
  border-radius: 10px;
  font-weight: bold;

  @include mediaQuery("sp") {
    top: 40%;
    height: 50%;
  }
  .service_content_dis {
    font-size: 1.8rem;
  }
  .content_icon {
    width: 5%;
    height: 60%;
    background-color: rgb(187, 51, 169);
    border-radius: 10px;
    opacity: 0.7;
    margin: 0 2% 0 2%;
    @include mediaQuery("sp") {
      display: none;
    }
  }
}
.service_content_s {
  position: absolute;
  @include centeringMl;
  width: 0;
  height: 25%;
  top: 65%;
  left: 5%;
  opacity: 0;
  background-color: rgb(196, 231, 255);
  color: $defontColor;
  border: $defShadow;
  border-radius: 10px;
  font-weight: bold;

  @include mediaQuery("sp") {
    top: 40%;
    height: 50%;
  }
  .service_content_dis {
    font-size: 1.8rem;
  }
  .content_icon {
    width: 5%;
    height: 60%;
    background-color: rgb(133, 133, 133);
    border-radius: 10px;
    opacity: 0.7;
    margin: 0 2% 0 2%;
    @include mediaQuery("sp") {
      display: none;
    }
  }
}
.service_item {
  opacity: 0;
  font-size: 2rem;
}
.service_title,
.service_title_r,
.service_title_s {
  font-family: $stitlefontStyle;
}
.service_item {
  color: $sevtfontColor;
}
@include mediaQuery("pc") {
  [class*="service-0"]:hover {
    box-shadow: $trsShadow;
    transform: translateY(-10px);
    opacity: 0.9;
    transition: 0.3s;
    .service_icon,
    .content_icon {
      transform: rotate(90deg);
      transition: 1s;
    }
    .service_content,
    .service_content_r,
    .service_content_s {
      width: 90%;
      opacity: 0.9;
      transition: 0.5s;
    }
    .service_item {
      opacity: 1;
      transition: 1.5s;
    }
  }
}
@include mediaQuery("tab") {
  [class*="service-0"] {
    box-shadow: $trsShadow;
    transform: translateY(-10px);
    opacity: 0.9;
    transition: 0.3s;
    .service_icon,
    .content_icon {
      animation: iconKaiten 4s linear infinite;
    }
    .service_title,
    .service_title_r,
    .service_title_s {
      font-size: 1.6rem;
    }
    .service_content,
    .service_content_r,
    .service_content_s {
      width: 90%;
      opacity: 0.9;
      .service_item,
      .service_content_dis {
        opacity: 1;
        font-size: 1.6rem;
        @include mediaQuery("sp") {
          margin: 10px;
        }
      }
    }
  }
}
.service_image {
  @include mediaQuery("sp") {
    display: none;
  }
}
.service_image_sp {
  display: none;
  @include mediaQuery("sp") {
    display: block;
  }
}

//system//
.service_header {
  position: relative;
  width: 100%;
  display: -webkit-flex;
  box-shadow: $defShadow;
  @include mediaQuery("sp") {
    height: 130px;
  }
  img {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
  .service_name {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    .name_h1 {
      position: absolute;
      width: 100%;
      top: 25%;
      @include centeringMc;
      font-family: $stitlefontStyle;
      font-size: $fontSize-h1;
      z-index: 1;
      color: rgb(66, 66, 66);
      @include mediaQuery("tab") {
        font-size: $fontSize-h1Tab;
      }
      @include mediaQuery("sp") {
        top: 10%;
        font-size: $fontSize-h1Sp;
      }
    }
  }
  .name_icon {
    position: absolute;
    width: 40%;
    height: 60%;
    top: 20%;
    background-color: $bodyColor;
    opacity: 0.8;
    @include mediaQuery("sp") {
      top: 10%;
    }
  }
  .service_name_content {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .service_name_content_icon {
    position: absolute;
    @include centeringMl;
    width: 75%;
    height: 25%;
    top: 50%;
    right: 0;
    background-color: rgb(196, 231, 255);
    opacity: 0.8;
    @include mediaQuery("sp") {
      width: 100%;
      height: 50%;
    }
    .service_name_p {
      margin-left: 3%;
      font-size: 1.6rem;
      font-weight: bold;
      color: black;
      @include mediaQuery("sp") {
        font-size: 1.4rem;
      }
    }
  }
}
.service_body {
  width: 100%;
  height: 100%;
  .service_body_title {
    width: 100%;
    height: 100px;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-item: center;
    background: linear-gradient(100deg, #ffffff, #dddddd, #d4d4d4);
    font-family: $stitlefontStyle;
    font-size: 2.2rem;
    box-shadow: $defShadow;
    color: rgb(66, 66, 66);
    border-radius: 5px;
    @include mediaQuery("tab") {
      font-size: 2rem;
    }
    @include mediaQuery("sp") {
      font-size: 1.6rem;
      height: 60px;
    }
    h2 {
      width: 80%;
      padding: 1%;
      margin-left: 2%;
      background: linear-gradient(100deg, #bfd3ff, #d0e8fc, #fffdfd);
      box-shadow: $defShadow;
      border-radius: 5px;
      opacity: 0.8;
    }
  }
  [class*="service_body_content-0"] {
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    width: 100%;
    height: auto;
    box-shadow: $defShadow;
    border-radius: 5px;
    z-index: 0;
    background: linear-gradient(100deg, #ffffff, #d0e8fc, #b0e0ff);

    .service_body_area-01 {
      margin: 10px;
      width: 90%;
      height: 20%;
      box-sizing: border-box;
      @include centeringMl;
      background: linear-gradient(100deg, #bfd3ff, #d0e8fc, #fffdfd);
      border-radius: 5px;
      opacity: 0.8;
      z-index: 0;
      .service_body_h3 {
        width: 60%;
        margin: 30px;
        padding: 50px 0 50px 20px;
        @include centeringMl;
        background: linear-gradient(100deg, #ffffff, #dddddd, #d4d4d4);
        font-family: $stitlefontStyle;
        font-size: 2.4rem;
        box-shadow: $defShadow;
        color: rgb(3, 3, 3);
        border-radius: 5px;
        @include mediaQuery("sp") {
          width: 80%;
          padding: 20px 0 20px 10px;
          margin: 10px auto 10px 10px;
          font-size: 2rem;
        }
      }
    }
    .service_body_p {
      width: 100%;
      height: auto;
      padding: 5px;
      z-index: 1;
      @include centeringMc;
      font-weight: bold;
    }
    .service_body_area-02 {
      width: 90%;
      height: auto;
      margin: 20px 20px 20px auto;
      border-radius: 5px;
      background: linear-gradient(100deg, #ffffff, #dddddd, #d4d4d4);
      opacity: 0.9;
      z-index: 1;
      .service_body_imgp {
        width: 100%;
        margin: auto;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .service_body_img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      z-index: -1;
    }
    .system_table {
      width: 100%;
      text-align: center;
      background-color: $bodyColor;
      tr {
        height: 100px;
        @include mediaQuery("sp") {
          height: 50px;
        }
      }
      th {
        background-color: rgb(227, 243, 255);
      }
    }
  }
}
.environment_area {
  font-size: 1.8rem;
  border: 0.4rem double #54585a;
  ul {
    margin: 0;
    padding: 0;

    @include mediaQuery("sp~") {
      @include centeringMc;
      border-bottom: 0.1rem solid #54585a;
      height: 100px;
    }
  }
  li {
    list-style: none;
    padding: 20px;
    @include centeringMl;
    height: 100%;
    @include mediaQuery("sp") {
      border-bottom: 0.1rem solid #54585a;
    }
  }
  .environment_head {
    width: 30%;
    background-color: rgb(227, 243, 255);
    @include mediaQuery("sp") {
      width: 100%;
    }
    @include mediaQuery("sp~") {
      border-right: 0.3rem double #54585a;
    }
  }
  .environment_li {
    width: 40%;
    height: 100%;
    @include mediaQuery("sp") {
      width: 100%;
    }
    @include mediaQuery("sp~") {
      border-right: 0.1rem solid #54585a;
    }
  }
  .sql_none {
    @include mediaQuery("sp") {
      display: none;
    }
  }
}
.button_area {
  width: 100%;
  margin-top: 20px;

  @include mediaQuery("sp~") {
    @include centeringMc;
  }
  a {
    text-decoration: none;
  }
  .btn_area_btn {
    width: 30%;
    margin: 2% 5% 0 5%;
  }
  @include mediaQuery("tab") {
    font-size: 1.6rem;
  }
  @include mediaQuery("sp") {
    button {
      height: 70px;
    }
  }
}
.system_last_text {
  margin-top: 20px;
}

//improv//
.improv_table {
  width: 95%;
  height: 100%;
  text-align: left;
  margin: 2% auto 2% auto;
  background-color: $bodyColor;
  @include mediaQuery("tab") {
    margin: 2% auto 2% auto;
    height: 30%;
  }
  th {
    width: 20%;
    background-color: rgb(227, 243, 255);
  }
  ul {
    padding-left: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }
}

//maimaku//
.micromacross {
  width: 100%;
  height: auto;
  box-shadow: 3px 3px 3px #999;
  background-color: #f3f2e9;
  color: #564c16;
  .maimaku_p {
    display: block;
    padding: 20px;
    font-size: 1.8rem;
    font-weight: bold;
    @include mediaQuery("sp") {
      padding: 20px 20px 0 20px;
    }
  }
  .maimaku_content {
    @include mediaQuery("sp~") {
      display: -webkit-flex;
      flex-direction: row-reverse;
    }
    padding: 20px;
    .maimaku_header {
      color: #a49c17;
      margin-bottom: 20px;
      .maimaku_title {
        font-size: 2.2rem;
        @include mediaQuery("sp") {
          font-size: 2rem;
        }
      }
      .maimaku_span {
        font-size: 1.6rem;
        margin-left: 20px;
        font-weight: normal;
      }
    }
    .maimaku_link {
      width: 100%;
      background-color: #564c16;
      height: 60px;
      margin-top: 50px;
      font-size: 1.8rem;
      @include mediaQuery("sp") {
        margin-top: 20px;
      }
      :hover {
        opacity: 0.5;
      }
      a {
        width: 100%;
        height: 100%;
        color: white;
        text-decoration: none;
        @include centeringMc;
      }
    }

    .maimaku_logo {
      position: relative;
      width: 80%;
      margin: 20px 20px 20px 0;
      @include centeringMc;
      @include mediaQuery("sp") {
        height: 100px;
        margin: 20px auto;
      }
      .maimaku_logo_img {
        width: 70%;
      }
      ::before {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        background-color: transparent;
        border-top: solid 5px #564c16;
        border-left: solid 5px #564c16;
        position: absolute;
        top: 0;
        left: 0;
      }
      ::after {
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        background-color: transparent;
        border-bottom: solid 5px #564c16;
        border-right: solid 5px #564c16;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}

//webproduct//

//web-bg
.web_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background: $webProvbgColor;
  background-size: 600%;
  animation: webbgChg 10s ease infinite;
  .web_bg_img img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.2;
  }
  .cube-01 {
    @include cubeStyle1;
  }
  .cube-02 {
    @extend.cube-01;
    top: 40%;
    left: 30%;
    animation: cubeMove2 alternate 8s ease-in-out infinite;
  }
  .cube-03 {
    @include cubeStyle2;
  }
  .cube-04 {
    @extend .cube-03;
    top: 50%;
    left: 10%;
    animation: cubeMove4 alternate 8s ease-in-out infinite;
  }
  .cube-05 {
    @include cubeStyle3;
  }
  .cube-06 {
    @extend .cube-05;
    top: 30%;
    left: 70%;
    animation: cubeMove6 alternate 5s ease-in-out infinite;
  }
  .cube-07 {
    @include cubeStyle4;
  }
}

//webproduct-content
.web_content {
  @include mediaQuery("sp") {
    td {
      min-width: 90px;
    }
  }
}
.web_content_header {
  position: relative;
  height: 400px;
  margin: 40px;
  display: -webkit-flex;
  margin-bottom: 80px;
  color: $defontColor;
  @include mediaQuery("tab") {
    height: 250px;
  }
  @include mediaQuery("sp") {
    height: 200px;
    width: 100%;
    margin: auto;
    margin-bottom: 40px;
  }
  .web_header_l {
    @include centeringMc;
    width: 40%;
    height: 80%;
    margin: auto;
    margin-left: 0;
    background: $newswebtitleBgcolor;
    border: $defShadow;
    opacity: 0.9;
    @include mediaQuery("sp") {
      width: 35%;
      height: 60%;
    }
    .content_icon {
      width: 50%;
      height: 60%;
      background-color: rgb(36, 36, 36);
      opacity: 0.7;
    }
  }
  .web_header_r {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 80%;
    height: 90%;
    margin: auto;
    background: linear-gradient(100deg, #ffffff, #b1ddee, #6cacc5);
    border: $defShadow;
    opacity: 0.9;
    @include mediaQuery("tab") {
      width: 100%;
      left: 0;
    }
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 5px;
    }
    .web_title {
      margin: 5%;
      font-family: $stitlefontStyle;
      font-size: $fontSize-h1;
      @include mediaQuery("tab") {
        font-size: $fontSize-h1Tab;
        margin: 3%;
      }
      @include mediaQuery("sp") {
        font-size: $fontSize-h1Sp;
        margin: 2%;
      }
    }
    .web_dis {
      margin: 5%;
      font-size: 1.6rem;
      font-weight: bold;
      @include mediaQuery("tab") {
        margin: 0;
        margin-left: 5%;
        font-size: 1.4rem;
        margin-bottom: 10px;
      }
    }
    .web_dis_2 {
      width: 100%;
      @include centeringMc;
    }
    .web_dis_2 a {
      margin: auto;
      padding: 20px;
      font-weight: bold;
      cursor: pointer;
      color: white;
      background-color: rgb(79, 184, 69);
      border: solid 1px rgb(129, 129, 129);
      box-shadow: $defontStyle;
      @include mediaQuery("sp") {
        padding: 5px;
      }
    }
    .web_dis_2 a:hover {
      transition: 0.5s;
      background-color: rgb(87, 118, 255);
    }
  }
}
.web_service_scroll {
  height: auto;
  .web_service_header {
    margin: 10px;
  }
  .web_s_title {
    font-family: $stitlefontStyle;
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 20px;
    @include mediaQuery("tab") {
      padding-top: 10px;
    }
    @include mediaQuery("tab") {
      font-size: 2rem;
    }
    .web_s_span {
      @include mediaQuery("sp") {
      }
    }
  }
  .web_service_body {
    .web_s_p {
      font-size: 1.8rem;
      margin-left: 2%;
      font-weight: bold;
    }
  }
}

.web_img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.1;
}
.web_s_table {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}
.web_service_section_f,
.web_service_section_l {
  width: 100%;
  height: auto;
  @include mediaQuery("pc") {
    @include centeringMl;
  }
  .section_left {
    position: relative;
    width: 60%;
    height: 650px;
    opacity: 0.9;
    z-index: 1;
    background-color: white;
    @include mediaQuery("tab") {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
    .web_service_header {
      height: 100px;
      @include mediaQuery("tab") {
        height: auto;
      }
    }
    .web_service_body {
      height: 400px;
      @include mediaQuery("tab") {
        height: auto;
      }
    }
    .web_service_footer {
      height: 150px;
      padding: 10px;
      @include mediaQuery("tab") {
        height: auto;
      }
    }
  }
}
.section_right {
  width: 400px;
  height: 650px;
  margin-left: 20px;
  opacity: 0.9;
  @include mediaQuery("tab") {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .section_r_inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(196, 231, 255);
    z-index: -1;
    .inner_lower {
      @include mediaQuery("tab") {
        padding-bottom: 20px;
      }
    }
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      z-index: -1;
    }
  }
}
.web_service_section_r {
  width: 100%;
  height: auto;
  @include mediaQuery("pc") {
    @include centeringMl;
  }
  flex-direction: row-reverse;
  .section_right-2 {
    position: relative;
    width: 60%;
    height: 650px;
    opacity: 0.9;
    background-color: white;
    @include mediaQuery("tab") {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
    .web_service_header {
      height: 100px;
      @include mediaQuery("tab") {
        height: auto;
      }
    }
    .web_service_body {
      height: 400px;
      @include mediaQuery("tab") {
        height: auto;
      }
    }
    .web_service_footer {
      height: 150px;
      padding: 10px;
      @include mediaQuery("tab") {
        height: auto;
      }
    }
  }
}
.section_left-2 {
  width: 400px;
  height: 650px;
  margin-right: 20px;
  opacity: 0.9;
  @include mediaQuery("tab") {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .section_l_inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(196, 231, 255);
    z-index: -1;
    .inner_lower {
      @include mediaQuery("tab") {
        padding-bottom: 20px;
      }
    }
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      z-index: -1;
    }
    .inner_upper,
    .inner_lower {
      width: 100%;
      height: 50%;
    }
  }
}
//section-inner//
.inner_upper {
  width: 100%;
  /*  height: 50%; */
  li {
    padding: 5% 0 5% 3%;
    font-size: 1.8rem;
    font-weight: bold;
    z-index: 2;
  }
}
.inner_lower {
  li {
    font-size: 1.8rem;
    font-weight: bold;
    z-index: 2;
  }
}
.web_b_area {
  width: 100%;
  @include centeringMc;
  a {
    text-decoration: none;
  }
  .btn_area_btn {
    width: 100%;
    @include centeringMc;
    margin: 30px 0;
  }
  button {
    width: 80%;
    height: 80px;
    font-weight: bold;
  }
}
.web_service_e {
  position: relative;
  margin-top: 80px;
  width: 100%;
  opacity: 0.8;
  background-color: white;
  .web_end_header {
    padding: 3% 0 0 3%;

    @include mediaQuery("tab") {
      font-size: 1.6rem;
    }
    @include mediaQuery("sp") {
      font-size: 2rem;
    }
  }
  .web_end_body {
    padding: 3%;
    h3 {
      @include mediaQuery("tab") {
        font-size: 1.6rem;
      }
      @include mediaQuery("sp") {
        font-size: 1.6rem;
      }
    }
    p {
      margin-left: 1%;
      padding-top: 1%;
    }
  }
}

//↓↓↓↓↓↓↓↓↓HB-ZONE↓↓↓↓↓↓↓↓↓↓↓//
/* ----------会社案内---------- */
.about-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background: url("images/about.webp") center;
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.5;
}
.about-panel {
  box-shadow: $defShadow;
  background-color: rgba(255, 255, 255, 0.8);
}
.about-p {
  padding: 10px 0 5px 25px;
  line-height: 30px;
}

/* -----------プライバシーポリシー---------- */
.privacy-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background: url("images/privacy-policy01.webp");
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.5;
}
.privacy-panel {
  box-shadow: $defShadow;
  background-color: rgba(255, 255, 255, 0.8);
}
.privacy-p {
  padding: 0 0 5px 25px;
}

/* ---------対応エリア---------- */
.area-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background: url("images/area.webp") center;
  background-attachment: fixed;
  background-size: cover;
  opacity: 0.5;
}
.area-panel {
  box-shadow: $defShadow;
  background-color: rgba(255, 255, 255, 0.8);
}

@media screen and (min-width: 768px) {
  .section-main {
    padding: 50px 120px 50px 100px;
  }
  .main-top {
    padding: 20px 0 20px 0;
  }
  .main {
    padding: 20px;
  }
  .section-bottom {
    display: flex;
    width: 100%;
  }
  .bottom {
    width: 25%;
    padding: 20px 0 20px 15px;
  }

  /* ----------会社案内---------- */
  /* .CompanyBrochureTable {
  } */
  .CompanyBrochureTable-row {
    display: flex;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #b5b5b5;
  }
  .CompanyBrochureTable-key {
    width: 25%;
    margin-left: 50px;
    line-height: 40px;
    font-size: 20px;
    color: #4f5255;
    font-weight: bold;
  }
  .CompanyBrochureTable-value {
    /* margin-left: 50px; */
    flex-grow: 1;
    line-height: 40px;
    font-size: 18px;
  }
  .CompanyBrochureTable-value_2 {
    /* margin-left: 50px; */
    flex-grow: 1;
    line-height: 40px;
    font-size: 18px;
  }
  .section-main {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .about-title01 {
    font-size: 80px;
    line-height: 80px;
    color: #008cff;
    font-family: "Century Gothic", "Lato", sans-serif;
  }
  .about-title02 {
    font-size: 25px;
    color: #008cff;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  /* ----------エリア---------- */
  .area-panel {
    padding: 50px 0 50px 0;
  }
  .area-p {
    padding: 0 0 5px 0px;
    text-align: center;
  }
  .map {
    margin: auto;
    width: 80%;
  }
  .area-title {
    background-color: #0060b0;
    padding: 30px 0 30px 0;
    width: 80%;
    color: #ffffff;
    text-align: center;
    margin: 0 auto 80px auto;
    font-size: 30px;
    line-height: 34px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .list-of-prefecture {
    text-align: left;
    border-collapse: collapse;
    width: 80%;
    margin: 50px auto 0px auto;
  }
  .midashi {
    width: 80%;
    color: #0060b0;
    text-align: center;
    margin: 55px auto 15px auto;
    border-bottom: solid 5px #0060b0;
    font-size: 30px;
    line-height: 34px;
  }
  .region,
  .prefecture {
    padding: 15px;
  }
  .list-row01,
  .list-row03,
  .list-row05,
  .list-row07,
  .list-row09 {
    background-color: #f7f7f7;
  }
  .list-row02,
  .list-row04,
  .list-row06,
  .list-row08 {
    background-color: #ffffff;
  }
  .region {
    width: 20%;
  }
  .prefecture {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  /* ----------会社案内---------- */
  /* .CompanyBrochureTable {
  } */
  .CompanyBrochureTable-row {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #b5b5b5;
  }
  .CompanyBrochureTable-key {
    width: 25%;
    margin-left: 10px;
    line-height: 40px;
    font-size: 20px;
    color: #4f5255;
    font-weight: bold;
  }
  .CompanyBrochureTable-value {
    /* margin-left: 50px; */
    flex-grow: 1;
    line-height: 40px;
    font-size: 18px;
  }
  .section-main {
    max-width: 98%;
    margin-right: auto;
    margin-left: auto;
  }
  .about-title01 {
    font-size: 50px;
    line-height: 50px;
    color: #008cff;
    font-family: "Century Gothic", "Lato", sans-serif;
  }
  .about-title02 {
    font-size: 20px;
    color: #008cff;
    margin-top: 7px;
    margin-bottom: 20px;
  }

  /* ----------プライバシーポリシー---------- */
  .privacy-title {
    text-align: center;
    padding: 0 0 10px 0;
  }

  /* ----------エリア----------- */
  .area-panel {
    padding: 30px 10px 50px 10px;
  }
  .area-p {
    padding: 0 0 5px 0px;
    text-align: left;
  }
  .map {
    margin: auto;
    width: 80%;
  }
  .area-title {
    background-color: #0060b0;
    padding: 10px 0 5px 0;
    width: 80%;
    color: #ffffff;
    text-align: center;
    margin: 0 auto 30px auto;
    font-size: 30px;
    line-height: 34px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .list-of-prefecture {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    margin: 50px auto 0px auto;
  }
  .midashi {
    width: 95%;
    color: #0060b0;
    text-align: center;
    margin: 55px auto 15px auto;
    border-bottom: solid 5px #0060b0;
    font-size: 25px;
    line-height: 34px;
  }
  .region,
  .prefecture {
    padding: 5px 0 5px 0;
  }
  .list-row01,
  .list-row03,
  .list-row05,
  .list-row07,
  .list-row09 {
    background-color: #f7f7f7;
  }
  .list-row02,
  .list-row04,
  .list-row06,
  .list-row08 {
    background-color: #ffffff;
  }
  .region {
    width: 22%;
  }
  .prefecture {
    width: 78%;
  }
  .section-main {
    padding: 20px 10px 20px 5px;
  }
  .main-top {
    padding: 10px 0 10px 0;
  }
  .main {
    padding: 10px 0px 10px 35px;
  }
  .bottom {
    padding: 0px 0 20px 40px;
  }
}
//↑↑↑↑↑↑↑↑↑↑↑HB-ZONE↑↑↑↑↑↑↑↑↑↑↑↑↑↑//

//contact//
.form_error_text {
  color: red;
}
//simulator//
.simulator_title_area {
  position: relative;
  @include centeringMc;
  background: $sevtitleBgcolor;
  box-shadow: $defShadow;
  height: 150px;
  font-family: $stitlefontStyle;

  z-index: 0;
  text-align: center;
  @include mediaQuery("sp") {
    height: 100px;
  }
  .simulator_title {
    z-index: 1;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
}
.simulator_body {
  box-shadow: $defShadow;
  padding: 20px;
}
.alert_message {
  width: 100%;
  height: 30px;
  color: red;
  @include centeringMl;
}
.choose_system {
  width: 100%;
  height: 60px;
  margin: 20px 0 20px 0;
  @include mediaQuery("sp~") {
    @include centeringM;
  }
  @include mediaQuery("sp") {
    margin: 10px 0 10px 0;
    height: auto;
  }
}
.choose_system_m {
  width: 100%;
  min-height: 350px;
  margin: 20px 0 20px 0;
  @include mediaQuery("sp~") {
    display: -webkit-flex;
  }
  @include mediaQuery("sp") {
    margin: 10px 0 10px 0;
    height: auto;
  }
}
.large_system {
  width: 35%;
  @include mediaQuery("sp") {
    width: 90%;
    height: 30px;
    margin-bottom: 10px;
  }
}
.simuarea_r {
  width: 65%;
  margin-left: 30px;
  background-color: rgb(240, 247, 255);
  box-shadow: $defShadow;
  @include mediaQuery("sp~") {
    @include centeringM;
  }
  @include mediaQuery("sp") {
    width: 100%;
    margin: 0;
  }
  .select_system {
    width: 30%;
    margin-left: 10px;
    @include mediaQuery("sp") {
      width: 100%;
      margin: 0;
      padding: 5px;
      border-bottom: double rgb(121, 121, 121);
    }
  }
  .select_area_l {
    @include mediaQuery("sp~") {
      @include centeringMl;
      flex-direction: row-reverse;
    }
  }
  .select_area_l,
  .select_area_m {
    width: 100%;
    height: 100%;
    padding: 10px;
    @include mediaQuery("sp~") {
      min-height: 40px;
      border-left: double rgb(121, 121, 121);
    }
    .detail_btn {
      margin: 5px;
    }
  }
}
.select_label_l {
  margin: 0 auto 0 0;
}
.choose_middle {
  position: relative;
  width: 35%;
  @include mediaQuery("sp") {
    width: 100%;
  }
}
.select_label_m {
  width: 100%;
  border-bottom: dashed rgb(134, 134, 134) 1px;
}
.number_area {
  @include centeringMr;
  margin: 0 0 3px auto;
}
.middle_number {
  width: 40px;
  margin-left: 5px;
}
.middle_sum {
  display: block;
  width: 80px;
  text-align: right;
}
.btn_area {
  @include centeringMl;
}
.select_btn {
  margin: 10px 40px 0 0;
  @include mediaQuery("sp") {
    margin: 0 40px 10px 0;
  }
}

.total_price_area {
  @include mediaQuery("sp~") {
    @include centeringMr;
  }

  margin: auto;
  .total_text {
    padding: 0 10px 0 10px;
  }
  .total_price {
    min-width: 30px;
    min-height: 30px;
    padding: 0 20px 0 20px;
    font-size: 2.2rem;
  }
}
.pre_text {
  color: red;
  margin: 10px 0;
}
.simulator_btn_area {
  @include centeringMc;
  .simulator_btn_area_btn {
    margin: 20px 15%;
    @include mediaQuery("sp") {
      width: 100%;
      height: 40px;
      margin: 20px 20px;
    }
  }
}
//contactform//
.contact_title_area {
  position: relative;
  @include centeringMc;
  background: $sevtitleBgcolor;
  box-shadow: $defShadow;
  height: 150px;
  font-family: $stitlefontStyle;

  z-index: 0;
  text-align: center;
  .contact_title {
    z-index: 1;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
}
.contact_body {
  box-shadow: $defShadow;
  padding: 20px;
}
.form_inner {
  @include centeringMc;
}
.my_form {
  width: 80%;
  @include mediaQuery("sp") {
    width: 100%;
    .my_form_p {
      margin: 5px 0 20px 0;
    }
  }
  .form_label {
    width: 30%;
    @include mediaQuery("sp") {
      width: 100%;
    }
    .simu_reset_btn {
      margin-top: 10px;
    }
  }
  .name_text,
  .company_text,
  .tel_text,
  .mail_text {
    width: 40%;
    margin: 10px 0;
    @include mediaQuery("sp") {
      width: 100%;
      margin: 5px 0;
    }
  }
  .privacy_check a {
    text-decoration: none;
    color: rgb(0, 129, 235);
  }
  .privacy_label {
    margin: 10px 0;
  }
  .message_text,
  .simu_text {
    width: 60%;
    min-height: 150px;
    margin: 10px 0;
    @include mediaQuery("sp") {
      width: 100%;
      margin: 10px 0 20px 0;
    }
  }
  .simu_inner_flex {
    @include mediaQuery("sp") {
      @include centeringMl;
      button {
        margin-left: 15px;
      }
    }
  }
  .simu_text {
    background-color: rgb(225, 238, 252);
    border: 1px solid;
    border-color: rgb(155, 155, 155);
    cursor: not-allowed;
    .simu_inner {
      width: 100%;
      height: 100%;
      margin: 10px 0;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      cursor: not-allowed;
      pointer-events: none;
      overflow: hidden;
      resize: none;
    }
  }
  .pre_text {
    color: red;
  }
  .submit_btn_area {
    @include centeringMc;
    .submit_btn_area_btn {
      width: 100px;
      height: 40px;
      margin: 20px 15%;
      @include mediaQuery("sp") {
        margin: 20px 20px;
      }
    }
  }
}
[class*="_input"] {
  @include mediaQuery("sp~") {
    display: -webkit-flex;
    align-items: center;
  }
}
[class^="error_text"] {
  color: red !important;
}
.required_item {
  color: red;
}
//done_form//
.done_form {
  height: 100vh;
  .done_form_inner_bold {
    font-size: 2.2rem;
    font-weight: bold;
    text-align: center;
    margin: 10%;
  }
  .done_form_p {
    font-size: 1.6rem;
    margin: 5px;
  }
  .back_btn {
    @include centeringMc;
    margin-top: 50px;
    button {
      width: 150px;
      height: 40px;
      cursor: pointer;
    }
  }
}
//simulatortop//
.simutop_title_area {
  position: relative;
  @include centeringMc;
  background: $sevtitleBgcolor;
  box-shadow: $defShadow;
  height: 150px;
  font-family: $stitlefontStyle;
  z-index: 0;
  text-align: center;
  .simutop_title {
    z-index: 1;
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.3;
  }
}
.simulator_flex {
  margin-top: 20px;
  color: $defontColor;
  @include mediaQuery("sp~") {
    @include centeringMc;
  }
  .sim_hr {
    margin: 10px 10%;
  }
  .system_simulator,
  .web_simulator {
    width: 50%;
    height: 400px;
    margin: 10px;
    background: linear-gradient(to right, #ffffff, #cfe7ff);
    box-shadow: $defShadow;
    @include mediaQuery("sp") {
      margin: 10px 0 0 0;
      width: 100%;
      /*  height: 350px; */
    }
    footer {
      padding-top: 60px;
      @include mediaQuery("sp") {
        padding-top: 30px;
      }
    }
    h2 {
      font-size: 2.2rem;
      padding-top: 50px;
      text-align: center;
    }
    p {
      margin: 20px;
      text-align: left;
    }
  }
  .sim_link {
    @include centeringMc;
    margin: 20px 0;
    :hover {
      opacity: 0.8;
    }
    a {
      @include centeringMc;
      width: 300px;
      height: 70px;
      background-color: rgb(57, 135, 252);
      text-decoration: none;
      color: white;

      box-shadow: $defShadow;
    }
    .case_btn {
      background-color: rgb(0, 168, 90);
    }
  }
}
//caseweb//
.case_body_h3 {
  width: 60%;
  margin: 30px;
  padding: 50px 0 50px 20px;
  @include centeringMl;
  background: linear-gradient(to right, #ffffff, #ffdaec, #ffdced);
  font-family: $stitlefontStyle;
  font-size: 2.4rem;
  box-shadow: $defShadow;
  color: rgb(3, 3, 3);
  border-radius: 5px;
  @include mediaQuery("sp") {
    width: 80%;
    padding: 20px 0 20px 10px;
    margin: 10px auto 10px 10px;
    font-size: 2rem;
  }
}
.area_content {
  overflow: hidden;
  @include mediaQuery("sp~") {
    display: -webkit-flex;
  }
}
.area_content_image {
  width: 50%;
  margin: 10px;
  @include mediaQuery("sp") {
    width: 95%;
    margin: 10px;
  }
}
.area_content_dis {
  margin-top: 20px;
}
.area_content_p {
  font-size: 1.6rem;
  margin: 10px;
  font-weight: bold;
}
.area_content_p_head {
  margin: 20px 10px;
  padding-left: 10px;
  font-size: 2rem;
  font-weight: bold;
  border-left: solid 5px;
  border-color: rgb(29, 77, 233);
}
.case_message {
  font-size: 1.6rem;
  margin: 20px;
}
.case_message_2 {
  margin: 20px;
}
