//container-size//
@mixin containerWidth {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: auto;
  @include mediaQuery("sp") {
    padding: 10px;
  }
}
//break-point//
$breakPoints: (
  "sp": "screen and (max-width: 767px)",
  "sp~": "screen and (min-width: 768px)",
  "tab": "screen and (max-width: 1024px)",
  "pc": "screen and (min-width: 1025px)",
);
//media-query('pc'or'tab'or'sp')
@mixin mediaQuery($breakPoint) {
  @media #{map-get($breakPoints, $breakPoint)} {
    @content;
  }
}
//font-size//
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
}
body {
  font-size: 1.4em;
}
a {
  text-decoration: none;
  cursor: pointer;
}
button {
  cursor: pointer;
}
//h1//
$fontSize-h1: 5.6rem;
$fontSize-h1Tab: 4.8rem;
$fontSize-h1Sp: 3.6rem;

//font-family//
$defontStyle: "Avenir", "Helvetica Neue", "Helvetica", "Arial", "Hiragino Sans",
  "ヒラギノ角ゴシック", YuGothic, "Yu Gothic", "メイリオ", Meiryo,
  "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//service-title//
$stitlefontStyle: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", serif;

//flex-centering-middle-center//
@mixin centeringMc {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
//flex-centering-middle-left//
@mixin centeringMl {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
//flex-centering-middle-right//
@mixin centeringMr {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
//flex-centering-middle//
@mixin centeringM {
  display: -webkit-flex;
  -webkit-align-items: center;
  align-items: center;
}
//color//
//body-backgroud-color//
$bodyColor: rgb(244, 247, 250);
//font-color//
$defontColor: rgb(49, 49, 49);
//service-title-bgcolor//
$sevtitleBgcolor: linear-gradient(100deg, #979797, #d4d4d4, #ffffff);
//news&webp-title-color//
$newswebtitleBgcolor: linear-gradient(100deg, #ffffff, #dddddd, #d4d4d4);
//service-titlle-ftcolor//
$sevtfontColor: rgb(32, 32, 32);
//service-item-font-color//
$sevfontColor: rgb(0, 0, 0);
//box-shadow//
$defShadow: 0 0 5px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
//trans-shadow//
$trsShadow: 0 15px 30px -5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);

//button-style//
.ok_btn {
  display: block;
  min-width: 80px;
  height: 40px;
  margin-top: 30px;
  border-radius: 5px;
  border: 0;
  color: white;
  cursor: pointer;
  letter-spacing: -1px;
  box-shadow: $defShadow;
  background-color: rgb(57, 135, 252);
  outline: none;
}
.clear_btn {
  display: block;
  min-width: 80px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  margin: 0 8%;
  margin-top: 30px;
  color: white;
  cursor: pointer;
  letter-spacing: -1px;
  box-shadow: $defShadow;
  background-color: rgb(241, 82, 90);
  opacity: 0;
  visibility: hidden;
  display: none;
  outline: none;
}
.cancel_btn {
  display: block;
  min-width: 80px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  margin: 0 8%;
  margin-top: 30px;
  color: white;
  cursor: pointer;
  letter-spacing: -1px;
  box-shadow: $defShadow;
  background-color: rgb(57, 135, 252);
  opacity: 0;
  visibility: hidden;
  display: none;
  outline: none;
}
.simu_b {
  display: block;
  width: 100%;
  height: 100px;
  border-radius: 0px;
  border: 0;
  color: white;
  letter-spacing: -1px;
  box-shadow: $defShadow;
  background-color: rgb(57, 135, 252);
  outline: none;
}
.contact_b {
  display: block;
  width: 100%;
  height: 100px;
  border-radius: 0px;
  border: 0;
  color: white;
  letter-spacing: -1px;
  box-shadow: $defShadow;
  background-color: rgb(129, 100, 63);
  outline: none;
}
.sample_b {
  display: block;
  width: 80%;
  height: 80px;
  border-radius: 0px;
  border: 0;
  margin: 40px auto 20px auto;
  color: white;
  letter-spacing: -1px;
  font-size: 2rem;
  box-shadow: $defShadow;
  background-color: rgb(57, 135, 252);
  outline: none;
}
.sample_b:hover {
  background-color: rgb(17, 61, 255);
}
.reset_btn {
  display: block;
  min-width: 80px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  margin: 0 8%;
  margin-top: 30px;
  color: white;
  cursor: pointer;
  letter-spacing: -1px;
  box-shadow: $defShadow;
  background-color: rgb(241, 82, 90);
  outline: none;
}

//WebProv-bg-color//
$webProvbgColor: linear-gradient(
  100deg,
  rgb(0, 26, 82),
  rgb(0, 21, 39),
  rgb(0, 0, 0)
);
//header-color//
$headerColor: linear-gradient(
  to bottom,
  rgba(255, 255, 255, 0.5),
  #cacaca,
  rgba(255, 255, 255, 0.5)
);
//footer-color//
$hooterColor: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0.5),
  #cacaca,
  rgba(0, 0, 0, 0.5)
);

//cube-color//
$cubeTop1: #004d96;
$cubeLeft1: #0055a5;
$cubeRight1: #00325c;

$cubeTop2: #0099b4;
$cubeLeft2: #039fbb;
$cubeRight2: #00728f;

$cubeTop3: #331172;
$cubeLeft3: #341685;
$cubeRight3: #16083d;

$cubeTop4: #7dbdb7;
$cubeLeft4: #9cc5c0;
$cubeRight4: #70b3b3;
//webbg-animation
@keyframes webbgChg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
//cube-style//
@mixin cubeStyle1 {
  position: absolute;
  top: 20%;
  left: 20%;
  opacity: 1;
  z-index: -1;
  animation: cubeMove1 alternate 8s ease-in-out infinite;
  .face {
    position: absolute;
    width: 60px;
    height: 60px;
    overflow: hidden;
  }
  .top {
    top: 0;
    left: 89px;
    background: $cubeTop1;
    transform: rotate(-45deg) skew(15deg, 15deg);
    transform: rotate(-45deg) skew(15deg, 15deg);
  }
  .left {
    top: 46px;
    left: 62px;
    background: $cubeLeft1;
    transform: rotate(15deg) skew(15deg, 15deg);
    transform: rotate(15deg) skew(15deg, 15deg);
  }
  .right {
    top: 46px;
    left: 115px;
    background: $cubeRight1;
    transform: rotate(-15deg) skew(-15deg, -15deg);
    transform: rotate(-15deg) skew(-15deg, -15deg);
  }
}
@mixin cubeStyle2 {
  position: absolute;
  top: 60%;
  left: 80%;
  opacity: 1;
  z-index: -1;
  animation: cubeMove3 alternate 8s ease-in-out infinite;
  .face {
    position: absolute;
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .top {
    top: 0;
    left: 89px;
    background: $cubeTop2;
    transform: rotate(-45deg) skew(15deg, 15deg);
    transform: rotate(-45deg) skew(15deg, 15deg);
  }
  .left {
    top: 77px;
    left: 44px;
    background: $cubeLeft2;
    transform: rotate(15deg) skew(15deg, 15deg);
    transform: rotate(15deg) skew(15deg, 15deg);
  }
  .right {
    top: 77px;
    left: 133px;
    background: $cubeRight2;
    transform: rotate(-15deg) skew(-15deg, -15deg);
    transform: rotate(-15deg) skew(-15deg, -15deg);
  }
}
@mixin cubeStyle3 {
  position: absolute;
  top: 60%;
  left: 80%;
  opacity: 1;
  z-index: -2;
  animation: cubeMove5 alternate 8s ease-in-out infinite;
  .face {
    position: absolute;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
  .top {
    top: 0;
    left: 89px;
    background: $cubeTop3;
    transform: rotate(-45deg) skew(15deg, 15deg);
    transform: rotate(-45deg) skew(15deg, 15deg);
  }
  .left {
    top: 23px;
    left: 76px;
    background: $cubeLeft3;
    transform: rotate(15deg) skew(15deg, 15deg);
    transform: rotate(15deg) skew(15deg, 15deg);
  }
  .right {
    top: 23px;
    left: 102px;
    background: $cubeRight3;
    transform: rotate(-15deg) skew(-15deg, -15deg);
    transform: rotate(-15deg) skew(-15deg, -15deg);
  }
}
@mixin cubeStyle4 {
  position: absolute;
  top: 60%;
  left: 80%;
  opacity: 0.4;
  z-index: -1;
  animation: cubeMove7 alternate 15s ease-in-out infinite;
  .face {
    position: absolute;
    width: 200px;
    height: 200px;
    overflow: hidden;
  }
  .top {
    top: 0;
    left: 89px;
    background: $cubeTop4;
    transform: rotate(-45deg) skew(15deg, 15deg);
    transform: rotate(-45deg) skew(15deg, 15deg);
  }
  .left {
    top: 155px;
    left: 0px;
    background: $cubeLeft4;
    transform: rotate(15deg) skew(15deg, 15deg);
    transform: rotate(15deg) skew(15deg, 15deg);
  }
  .right {
    top: 155px;
    left: 179px;
    opacity: 0.5;
    background: $cubeRight4;
    transform: rotate(-15deg) skew(-15deg, -15deg);
    transform: rotate(-15deg) skew(-15deg, -15deg);
  }
}

//cube-move//
@keyframes cubeMove1 {
  0% {
    top: 20%;
    left: 20%;
  }
  50% {
    top: 20%;
    left: 60%;
  }
  100% {
    top: 80%;
    left: 60%;
  }
}
@keyframes cubeMove2 {
  0% {
    top: 40%;
    left: 30%;
  }
  50% {
    top: 40%;
    left: 60%;
  }
  100% {
    top: 80%;
    left: 20%;
  }
}
@keyframes cubeMove3 {
  0% {
    top: 60%;
    left: 80%;
  }
  50% {
    top: 40%;
    left: 20%;
  }
  100% {
    top: 80%;
    left: 20%;
  }
}
@keyframes cubeMove4 {
  0% {
    top: 50%;
    left: 10%;
  }
  50% {
    top: 50%;
    left: 70%;
  }
  100% {
    top: 20%;
    left: 70%;
  }
}
@keyframes cubeMove5 {
  0% {
    top: 50%;
    left: 40%;
  }
  50% {
    top: 70%;
    left: 40%;
  }
  100% {
    top: 20%;
    left: 40%;
  }
}
@keyframes cubeMove6 {
  0% {
    top: 30%;
    left: 70%;
  }
  50% {
    top: 60%;
    left: 20%;
  }
  100% {
    top: 80%;
    left: 80%;
  }
}
@keyframes cubeMove7 {
  0% {
    top: 60%;
    left: 80%;
  }
  50% {
    top: 60%;
    left: 10%;
  }
  100% {
    top: 50%;
    left: 10%;
  }
}

//icon-animation//
@keyframes iconKaiten {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.simu_b:hover,
.contact_b:hover {
  opacity: 0.8;
  cursor: pointer;
}
